import Vue from 'vue';
import VueRouter from 'vue-router';

const Home = ()=> import('../components/startAnimation.vue');
const Download = ()=> import('../components/download.vue')
const ModelList = ()=> import('../components/modelList.vue')
const ModelFix = ()=> import('../components/modelFix.vue')
const Convert = ()=> import('../components/convert.vue')
const ModelConfig = ()=> import('../components/modelConfig.vue')
// const EdnAni = ()=> import('../components/ednAni.vue')

const Draghw = ()=> import('../components/draghw.vue')
Vue.use(VueRouter);

const routes = [  
    { 
      path: '/',
      redirect: '/home'
    },  
    {
      path: '/home',
      name: 'home',
      component: Home,
    }, 
    {
      path: '/model-list',
      name: 'ModelList', 
      component: ModelList
    },
    {
      path: '/download',
      name: 'download', 
      component: Download
    },
    {
      path: '/model-fix',
      name: 'ModelFix', 
      component: ModelFix
    },
    {
      path: '/convert',
      name: 'Convert', 
      component: Convert
    }, 
    {
      path: '/model-config',
      name: 'ModelConfig', 
      component: ModelConfig
    },
    // {
    //   path: '/edn-ani',
    //   name: 'EdnAni', 
    //   component: EdnAni
    // },
    {
      path: '/draghw',
      name: 'Draghw', 
      component: Draghw
    }
]
const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router